@font-face {
  font-family: "Marianne";
  src: local("Marianne"),
  url("assets/fonts/Marianne-Regular.woff") format("woff");
}
body {
  font-family: "Marianne", serif;
}
.gestion {
  background: linear-gradient(170deg, #5770BE 0%, #5770BE 100%);
}
.ginet {
  background: linear-gradient(170deg, #00AC8C 0%, #00AC8C 100%);
}
.portailrh {
  background: linear-gradient(170deg, #E18B63 0%, #E18B63 100%);
}

/* https://codepen.io/Delvezio/pen/ExawqVa */
.card {
  min-height: 500px;
  margin: 0 0 10px 0;
  padding: 0 .8rem 1.2rem .8rem;
  border: none;
  border-radius: 0;
  color: rgba(0, 0, 0, 1);
  letter-spacing: .05rem;
  box-shadow: 0 0 21px rgba(0, 0, 0, .27);
}

.card-title{
  font-weight: normal;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0.4;
  font-size: 1.5rem;
}

.card h2 {
  color: #ffffff;
  font-weight: bolder;
  margin-top: 30px;
}

.card .ico-card{
  width: 35%;
  height: 35%;
  color:rgba(255,255,255,1);
  opacity: .5;
}

.card-text {
  color: #ffffff;
}

.portal-card-footer {
  background: transparent;
  border-top:0;
}

header h1 {
  color: #282c34;
}
header {
  margin-bottom: 25px;
  border-bottom: 1px solid #0e7dc4;
}
.accordion-button:focus {
  border-color: red;
}

.accordion-button:not(.collapsed) {
  color: #282c34;
  background-color: transparent;
}

footer {
  border-top: 1px solid #f1f1f1;
  padding: 20px;
  margin-top: 50px;
}

@media (max-width: 991px) {
  .card {
    min-height: 300px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .card {
    min-height: 620px;
  }
}


